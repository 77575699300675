<template>
	<div>
		<div class="row mt-4">
			<div class="col-xs-12 col-lg-8 d-flex">
				<div class="h2 text-muted py-3">
					{{ translate('general_information') }}
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<ul class="list-group w-100">
					<form
						@submit.prevent="prepareForStorage()"
						@keydown="clearValidationError($event.target.name);"
						@change="clearValidationError($event.target.name);">
						<li
							v-if="showSponsor"
							class="list-group-item">
							<div class="row">
								<div class="col-12">
									<div class="my-2">
										<span class="h4">{{ translate('sponsor') }}</span>
										<span
											v-b-tooltip.hover.html.right
											:title="translate('sponsor_description', {company: companyName, supportLink: companyEmail, support: companyEmail })"
											class="text-muted small mx-2">
											<i class="fas fa-lg fa-info-circle" />
										</span>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<div class="form-group">
										<input-text
											id="sponsor"
											:label="translate('sponsor_information')"
											:setter-value="sponsor.id"
											:errors="validationErrors['sponsor']"
											:required="true"
											:disabled="disableSponsor"
											type="text"
											@dataChanged="sponsor.id = $event" />
									</div>
								</div>
							</div>
						</li>
						<li class="list-group-item border-top-0'">
							<div class="row">
								<div class="col-12">
									<div
										class="h4 my-2">
										{{ translate('personal_information') }}
									</div>
								</div>
							</div>
							<div class="row m-3">
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.first_name`"
											:label="translate('first_name')"
											:setter-value="form.first_name"
											:errors="validationErrors[`${steps[$route.name].validation}.first_name`]"
											:required="true"
											type="text"
											@dataChanged="form.first_name = $event" />
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.last_name`"
											:label="translate('last_name')"
											:setter-value="form.last_name"
											:errors="validationErrors[`${steps[$route.name].validation}.last_name`]"
											:required="true"
											type="text"
											@dataChanged="form.last_name = $event" />
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.email`"
											:label="translate('email')"
											:setter-value="form.email"
											:errors="validationErrors[`${steps[$route.name].validation}.email`]"
											:required="true"
											:max="maxEmailLength"
											type="text"
											@dataChanged="form.email = $event" />
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<label for="mobile_number">
											{{ translate('mobile_number') }}
											<span class="text-danger">*</span>
										</label>
										<vue-tel-input
											:id="`${steps[$route.name].validation}.mobile_number`"
											v-model="form.mobile_number"
											:required="true"
											:label="translate('mobile_number')"
											:placeholder="translate('mobile_number')"
											:class="validationErrors[`${steps[$route.name].validation}.mobile_number`] ? 'is-invalid' : ''"
											v-bind="bindProps"
											type="text"
											:name="`${steps[$route.name].validation}.mobile_number`"
											class="form-control rounded-1"
											@dataChanged="form.mobile_number = $event" />
										<template v-if="validationErrors[`${steps[$route.name].validation}.mobile_number`]">
											<span
												v-for="error in validationErrors[`${steps[$route.name].validation}.mobile_number`]"
												:key="error"
												class="invalid-feedback animated fadeIn"
												v-text="error" />
										</template>
									</div>
								</div>
							</div>
						</li>
						<li class="list-group-item">
							<div class="row">
								<div class="col-12">
									<div class="my-2">
										<div class="form-check">
											<input
												id="createAccount"
												v-model="createAccount"
												name="createAccount"
												class="form-check-input"
												type="checkbox">
											<label
												class="form-check-label h5"
												for="createAccount">
												{{ translate('create_an_account') }}
											</label>
										</div>
									</div>
								</div>
							</div>
						</li>
						<li
							v-if="createAccount"
							class="list-group-item">
							<div class="row">
								<div class="col">
									<div class="h4 my-2">
										{{ translate('account_info') }}
									</div>
								</div>
							</div>
							<div class="row m-3">
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.username`"
											:label="translate('username')"
											:setter-value="form.username"
											:errors="validationErrors[`${steps[$route.name].validation}.username`]"
											:required="true"
											type="text"
											@dataChanged="form.username = $event" />
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.password`"
											:label="translate('password')"
											:setter-value="form.password"
											:errors="validationErrors[`${steps[$route.name].validation}.password`]"
											:required="true"
											type="password"
											@dataChanged="form.password = $event" />
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.password_confirmation`"
											:label="translate('password_confirmation')"
											:setter-value="form.password_confirmation"
											:errors="validationErrors[`${steps[$route.name].validation}.password_confirmation`]"
											:required="true"
											type="password"
											class="text-nowrap"
											@dataChanged="form.password_confirmation = $event" />
									</div>
								</div>
							</div>
						</li>
					</form>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-4 ml-auto my-4 text-right">
				<div
					class="btn-group w-100"
					role="group">
					<b-button
						:disabled="validating || !!preparing"
						variant="primary"
						size="lg"
						@click="prepareForStorage()">
						<i
							v-if="validating"
							class="fas fa-spinner fa-spin mr-2" />{{ translate('continue') }}
					</b-button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { VueTelInput } from 'vue-tel-input';
import InputText from '@/components/InputText';
import PaymentProcessor from '@/util/PaymentProcessor';
import { MAX_EMAIL_LENGTH } from '@/settings/Validations';
import { BLACKLIST_UNSET_FIELDS } from '@/settings/Purchase';
import { customer } from '@/settings/Roles';
import WindowSizes from '@/mixins/WindowSizes';
import CommonMix from './mixins/Common';
import { VALID_COUNTRIES as availableCountries } from '@/settings/Country';

export default {
	name: 'CheckoutOldPersonalInformation',
	components: { InputText, VueTelInput },
	filters: {
		withCero: (value) => ((value <= 9) ? `0${value}` : value),
	},
	mixins: [CommonMix, WindowSizes],
	data() {
		return {
			alert: new this.$Alert(),
			paymentProcessor: new PaymentProcessor(),
			customer,
			sponsor: {
				id: '',
				name: '',
			},
			hasSponsor: '',
			disableSponsor: false,
			showSponsor: true,
			identityId: null,
			maxEmailLength: MAX_EMAIL_LENGTH,
			blackListFields: BLACKLIST_UNSET_FIELDS,
			bindProps: {
				inputOptions: {
					showDialCode: true,
				},
				disabledFetchingCountry: true,
			},
			form: {
				first_name: '',
				last_name: '',
				email: '',
				mobile_number: '',
				username: '',
				password: '',
				password_confirmation: '',
			},
			canLeave: false,
			companyName: process.env.VUE_APP_TITLE,
			companyEmail: process.env.VUE_APP_COMPANY_EMAIL,
			createAccount: true,
			availableCountries,
		};
	},
	computed: {
		path() {
			return this.createAccount ? 'customer' : 'guest';
		},
	},
	created() {
		this.bindProps.defaultCountry = this.country;
		this.bindProps.onlyCountries = this.availableCountries;
		this.getStoredInformation();
		if (this.$user.auth() === true) {
			this.sponsor.id = String(this.$user.details().id);
			this.disableSponsor = true;
		} else {
			const sponsorId = this.$replicated.replicatedSponsor();
			if (sponsorId !== null) {
				this.sponsor.id = String(sponsorId);
				this.showSponsor = false;
			}
		}
	},
	methods: {
		getStoredInformation() {
			const {
				sponsor, identity_id: identityId, create_account: createAccount,
			} = this.getStepInformation();
			const dataTemp = { ...this.getStepInformation().personal_information };
			if (!Object.keys(dataTemp).length) {
				return null;
			}
			this.createAccount = createAccount;
			this.sponsor.id = sponsor;
			this.hasSponsor = true;
			this.identityId = identityId;

			Object.keys(this.form).forEach((key) => {
				if (typeof dataTemp[key] !== 'undefined' && !this.blackListFields.includes(key)) {
					this.form[key] = dataTemp[key];
				}
			});
			return null;
		},
		prepareForStorage() {
			this.preparing = true;
			let form = { ...this.form };
			if (this.path === 'guest') {
				form = {
					first_name: this.form.first_name,
					last_name: this.form.last_name,
					email: this.form.email,
					mobile_number: this.form.mobile_number,
				};
			}
			let payload = {
				step: this.steps[this.$route.name].validation,
				sponsor: this.sponsor.id,
				personal_information: form,
				identity_id: this.identityId,
				path: this.path,
				create_account: this.createAccount,
			};

			this.validateStep(payload).then(() => {
				// Save Identity ID
				const identityData = {
					first_name: this.form.first_name,
					last_name: this.form.last_name,
					email: this.form.email,
					mobile_number: this.form.mobile_number,
					identity_id: this.identityId,
				};
				this.paymentProcessor.createIdentity(identityData).then((response) => {
					this.identityId = response.response.identity_id;
					payload = { ...payload, identity_id: this.identityId };
					this.saveStep(payload);
				}).catch(() => {
					this.alert.toast('error', this.translate('identity_error'), { timer: 6000 });
				});
			}).catch((error) => {
				if (error.status === 422) {
					const { cart_id: cartId } = error.errors;
					if (typeof cartId !== 'undefined') {
						let response = '';
						cartId.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 6000 });
						setTimeout(() => {
							this.canLeave = true;
							this.$router.replace({ name: 'Store' });
						}, 6000);
					}
				}
			}).finally(() => { this.preparing = false; });
		},
	},
	beforeRouteLeave(to, from, next) {
		if (to.name.includes('CheckoutOld')) {
			next();
			const alert = new this.$Alert();
			alert.close();
		} else if (to.name.includes('Store')) {
			next();
			if (this.canLeave) {
				const alert = new this.$Alert();
				alert.close();
				this.removeRegister();
			}
		}
	},
};
</script>
